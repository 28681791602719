import { Typography, styled } from "@mui/material";
import { getWeekNumber } from "./utils/date";

const columns = [
  {
    name: (
      <Typography
        variant="bold"
        sx={{
          fontSize: 10,
        }}
      >
        WK
      </Typography>
    ),
    selector: row => (
      <Typography
        variant="bold"
        sx={{
          fontSize: 10,
        }}
      >
        {row.title}
      </Typography>
    ),
    width: '48px',
  },
];

export default function getColumns(
  width,
  YAxisWidth,
  data,
  handleRegisteredCellChange,
  handleRegisterCellBlur,
  searchParams,
  year,
) {
  const a = [...columns];
  const currentWeek = getWeekNumber() % 52;
  const isCurrentYear = year === new Date().getFullYear();

  Array(52).fill(0).forEach((_, columnIndex) => {
    a.push(
      {
        name: columnIndex + 1,
        selector: (row, rowIndex) => {
          if (rowIndex === 2) {
            return searchParams.get('club') ? (
              <Input
                name={columnIndex}
                value={data?.[+searchParams.get('club')][2][+columnIndex] || ''}
                onChange={handleRegisteredCellChange}
                onBlur={handleRegisterCellBlur}
                isBold={isCurrentYear && currentWeek === columnIndex + 1}
              />
            ) : (
              data?.all[2][`${columnIndex}`] || ''
            )
          }

          if (rowIndex === 3) {
            return row[columnIndex] 
              ? (row[columnIndex] || 0) + "%"
              : '';
          }

          return row[columnIndex]
        },
        minWidth: (width - 21 - YAxisWidth) / 52 + 'px',
        style: {
          fontWeight: (isCurrentYear && currentWeek === columnIndex + 1) ? 'bold' : 'unset',
        }
      });
  });

  return a

}

const Input = styled('input')(({ isBold }) => ({
  width: '100%',
  height: 36,
  padding: 0,

  backgroundColor: 'transparent',
  border: 'none',
  outline: 'none',

  fontFamily: 'inherit',
  fontWeight: isBold ? 'bold' : 'unset',
  color: 'inherit',
  fontSize: 10,
  textAlign: 'center',
}));